////////////////////////////////////////////////////////////////////////////////
//                                                                            //
//  APIV1                                                                     //
//                                                                            //
//  Some common API functions to make dealing with the api a little easier.   //
//  Uses the wordpress json api v1, so you'll notice that everything is v1    //
//  this v1 that. Will we ever use v2 probably, eventually and when we do     //
//  we'll adopt the same convention and use the same set up of file just      //
//  everything will be V2 instead                                             //
//                                                                            //
////////////////////////////////////////////////////////////////////////////////

import { getEnv } from '@kit/utils/EnvironmentHelper'
import { wpJWTGetToken } from '@kit/utils/auth/wp-jwt/WPJWT'

const ctx = typeof window !== 'undefined' ? window : null

export const base  = ctx ? ctx.location.protocol+"//"+ctx.location.hostname  : 'http://wordpress'

export const API_LOC_DEFAULT_NS = ctx ? '/cms-service/index.php/wp-json/v1/' : '/index.php/wp-json/v1/'
export const API_LOC_WPJWT_NS = ctx ? '/cms-service/index.php/wp-json/jwt-auth/v1/' : '/index.php/wp-json/jwt-auth/v1/'


const validateAPILocation = (apiLocation) => {
  const apiLoc = apiLocation || API_LOC_DEFAULT_NS
  if(!apiLoc) {
    throw new Error("No API location")
  } 
  else if(apiLoc != API_LOC_DEFAULT_NS && apiLoc != API_LOC_WPJWT_NS) {
    throw new Error("API location must be either the API_LOC_DEFAULT_NS or API_LOC_WPJWT_NS exported constants from the APIV1 module")
  }
  return apiLoc
}


/**
 * Perform GET
 * @param {String} endpoint. Required. The endpoint
 * @param {Boolean} external.  if truem then just the endpoint, i.e. treat it as external
 * @returns the data
 * 
 */
export const get = async (endpoint, external, apiLoc) => {

  const apiLocation = validateAPILocation(apiLoc)

  const options = {
    method:'get'
  }

  if(!external) {
    const jwt = wpJWTGetToken()
    if(jwt) {
      options["headers"] = { "Authorization":`Bearer ${jwt}` } 
    }
  }


  const response = external ? await fetch(endpoint) : await fetch(`${base}${apiLocation}${endpoint}`, options)

  if(response.status != 200) {
    return {"error":true, "status":response.status, "message":"server encountered error "+response.status }
  } else {
    const data = await response.json()
    return data
  }
}


export const getBaseURL = (endpoint, apiLoc) => {
  const apiLocation = validateAPILocation(apiLoc)
  return `${base}${apiLocation}${endpoint}`
}

/**
 * Perform POST
 * @param {String} endpoint. Required. the endpoint 
 * @param {Boolean} data. Optiona. The data to pass along  
 * @returns the data
 * 
 */
export const post = async(endpoint, data, apiLoc) => {

  try {

    const apiLocation = validateAPILocation(apiLoc)

    //Grab the token and add it to the header if there is a token.
    const jwt = wpJWTGetToken()
    const mergeBearerHeader = jwt ? { "Authorization":`Bearer ${jwt}` } : {}

    let response = null 
    try {
      response = await fetch(`${base}${apiLocation}${endpoint}`, { 
        method:'post',
        headers: {
          'Content-Type': 'application/json',
          ...mergeBearerHeader
        },
        body: JSON.stringify(data)
      })
    } catch(e) {
      throw new Error("(n69432) "+e.message)
    }

    let responseData = null
    try {
      responseData = await response.json()
    } catch(e) {
      throw new Error("(hghe73) "+e.message)
    }

    if(typeof responseData == "string") {
      return JSON.parse(responseData)
    }

    return responseData

  } catch(e) {
    throw new Error('(mvjf83) '+e.message)
  }
}


//https://localhost/cms-service/index.php/wp-json/v1/home
export const contactUsFormEndpoint = async(jsonFormData) => {
  return post(`contact-us-form-endpoint`, jsonFormData)
}

/**
 * The common pagination endpoint. Used for tables.
 * Puts together this kind of url that the wordpress api can understand
 * https://localhost/cms-service/index.php/wp-json/v1/job-openings/close_date:DATE/desc/1:3
 * 
 * @param {String} endpoint 
 * @param {String} sortField 
 * @param {String} cast 
 * @param {String} direction 
 * @param {String} page 
 * @param {String} numberPerPage 
 * @returns the data
 * 
 */

export const paginate = async(endpoint, sortField, cast, direction, page, numberPerPage) => {
  const sortParam = cast ? `${sortField}:${cast}` : sortField 
  const paginationParam = numberPerPage ? `/${page}:${numberPerPage}` : ''
  const response = await fetch(`${base}${API_LOC_DEFAULT_NS}${endpoint}/${sortParam}/${direction}${paginationParam}`)
  const data = await response.json()
  return data
}

/**
 * The common total endpoint. Used for tables. 
 * @param {String} endpoint Required. the endpoint
 * @returns the total
 */
export const total = async(endpoint) => {
  const response = await fetch(`${base}${API_LOC_DEFAULT_NS}${endpoint}/total`)
  const data = await response.json()
  return data[0]
}


/**
 * Fires of a GET request to this url that the wordpress api can understand:
 * https://localhost/cms-service/index.php/wp-json/v1/locations/slugs
 * The point is to return a list of slugs that can be used when this thing is 
 * generating a sitemap. 
 * 
 * @param {String} slugBasePlural. Required.  
 * @returns {Array} an array of slugs to use 
 */
 export const slugs = async(slugBasePlural) => {
  const response = await fetch(`${base}${API_LOC_DEFAULT_NS}${slugBasePlural}/slugs`)
  const data = await response.json()
  return data
}

/**
 * Fires of a GET request to this url that the wordpress api can understand:
 * https://localhost/cms-service/index.php/wp-json/v1/locations/pages
 * The point is to return a list of pages that can be used when this thing is 
 * generating a sitemap. 
 * 
 * @param {String} slugBasePlural. Required.  
 * @returns {Array} an array of slugs to use 
 */
 export const pages = async(slugBasePlural) => {
  const response = await fetch(`${base}${API_LOC_DEFAULT_NS}${slugBasePlural}/pages`)
  const data = await response.json()
  return data
}


//https://www.stefanjudis.com/snippets/how-trigger-file-downloads-with-javascript/
//https://stackoverflow.com/questions/6346021/how-do-i-know-when-download-from-a-url-is-completed/43617850


export const saveFile = (fileOrURL, fileName) => {
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = URL.createObjectURL(fileOrURL); //typeof fileOrURL == "string" ? fileOrURL : URL.createObjectURL(fileOrURL);
  link.download = fileName

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
}

//A promiseable helper to download a file from a url.
export const downloadFile = (file, fileName) => {
  return new Promise((resolve,reject) => {

    const request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.open('GET', file);
    //request.addEventListener('load', console.log('load ' + file));
    request.addEventListener('error', (e) => {reject(e.message)});
    //request.addEventListener('progress', console.log('progress ' + file));
    request.addEventListener('load', function () {
      saveFile(request.response, fileName)
      resolve()
    });

    try {
      request.send();
    } catch(e) {
      reject(e.message)
    }
  })
}




/**
 * The endpoint for the google custom search engine
 * 
 * @param {string, Required} searchTerm 
 * @param {integer, Optional} startIndex
 * 
 * @returns 
 */
export const googleCSE = async(searchTerm, startIndex_) => {
  const startIndex = startIndex_ || 1 
  const data = await get(`https://www.googleapis.com/customsearch/v1?key=${getEnv("GOOGLE_CSE_API_KEY")}&start=${startIndex}&cx=${getEnv("GOOGLE_CSE_ID")}&q=${searchTerm}`, true)
  return data
}

/*

{
  "kind": "customsearch#search",
  "url": {
    "type": "application/json",
    "template": "https://www.googleapis.com/customsearch/v1?q={searchTerms}&num={count?}&start={startIndex?}&lr={language?}&safe={safe?}&cx={cx?}&sort={sort?}&filter={filter?}&gl={gl?}&cr={cr?}&googlehost={googleHost?}&c2coff={disableCnTwTranslation?}&hq={hq?}&hl={hl?}&siteSearch={siteSearch?}&siteSearchFilter={siteSearchFilter?}&exactTerms={exactTerms?}&excludeTerms={excludeTerms?}&linkSite={linkSite?}&orTerms={orTerms?}&relatedSite={relatedSite?}&dateRestrict={dateRestrict?}&lowRange={lowRange?}&highRange={highRange?}&searchType={searchType}&fileType={fileType?}&rights={rights?}&imgSize={imgSize?}&imgType={imgType?}&imgColorType={imgColorType?}&imgDominantColor={imgDominantColor?}&alt=json"
  },
  "queries": {
    "request": [
      {
        "title": "Google Custom Search - wecare",
        "totalResults": "71",
        "searchTerms": "wecare",
        "count": 10,
        "startIndex": 1,
        "inputEncoding": "utf8",
        "outputEncoding": "utf8",
        "safe": "off",
        "cx": "c9459c0fcf92baa81"
      }
    ],
    "nextPage": [
      {
        "title": "Google Custom Search - wecare",
        "totalResults": "71",
        "searchTerms": "wecare",
        "count": 10,
        "startIndex": 11,
        "inputEncoding": "utf8",
        "outputEncoding": "utf8",
        "safe": "off",
        "cx": "c9459c0fcf92baa81"
      }
    ]
  },
  "context": {
    "title": "sths wecare"
  },
  "searchInformation": {
    "searchTime": 0.254655,
    "formattedSearchTime": "0.25",
    "totalResults": "71",
    "formattedTotalResults": "71"
  },
  "items": [
    {
      "kind": "customsearch#result",
      "title": "WeCare: Home",
      "htmlTitle": "<b>WeCare</b>: Home",
      "link": "https://sths.devapps-dlnkeicmwjs0192.com/",
      "displayLink": "sths.devapps-dlnkeicmwjs0192.com",
      "snippet": "Learn about the patient-centered approach and available care options provided by WeCare Health Services, serving people in Humboldt, Trinity and surrounding ...",
      "htmlSnippet": "Learn about the patient-centered approach and available care options provided by <b>WeCare</b> Health Services, serving people in Humboldt, Trinity and surrounding&nbsp;...",
      "cacheId": "8hOKb74bKfsJ",
      "formattedUrl": "https://sths.devapps-dlnkeicmwjs0192.com/",
      "htmlFormattedUrl": "https://sths.devapps-dlnkeicmwjs0192.com/",
      "pagemap": {
        "cse_thumbnail": [
          {
            "src": "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRKdbWKSwpACh7bRwoohHXBMPZZGHO0o9Eo09EZz68Bw2P00nOu_jO9LwBO",
            "width": "275",
            "height": "183"
          }
        ],
        "metatags": [
          {
            "og:image": "https://sths.devapps-dlnkeicmwjs0192.com/cms-service/wp-content/uploads/2021/10/sths-test-gallery-img1.jpg",
            "og:type": "website",
            "og:image:alt": "Shows a picture of a hospital in the evening",
            "viewport": "width=device-width, initial-scale=1.0",
            "og:title": "Home",
            "og:description": "Learn about the patient-centered approach and available care options provided by WeCare Health Services, serving people in Humboldt, Trinity and surrounding counties of northern California. Read about our teams, recent news, login to your patient portal, find locations closest to you, browse job openings and join our email list."
          }
        ],
        "cse_image": [
          {
            "src": "https://sths.devapps-dlnkeicmwjs0192.com/cms-service/wp-content/uploads/2021/10/sths-test-gallery-img1.jpg"
          }
        ]
      }
    },
    {
      "kind": "customsearch#result",
      "title": "Jennifer - WeCare",
      "htmlTitle": "Jennifer - WeCare",
      "link": "https://sths.devapps-dlnkeicmwjs0192.com/contact/654",
      "displayLink": "sths.devapps-dlnkeicmwjs0192.com",
      "snippet": "Our team members at WeCare decorative blue waves. WeCare logo. Smiling healthcare professional. Jennifer. Medical Biller at Mad River, Scotia Bluffs and ...",
      "htmlSnippet": "Our team members at <b>WeCare</b> decorative blue waves. <b>WeCare</b> logo. Smiling healthcare professional. Jennifer. Medical Biller at Mad River, Scotia Bluffs and&nbsp;...",
      "cacheId": "72L5BSpnu-oJ",
      "formattedUrl": "https://sths.devapps-dlnkeicmwjs0192.com/contact/654",
      "htmlFormattedUrl": "https://sths.devapps-dlnkeicmwjs0192.com/contact/654",
      "pagemap": {
        "cse_thumbnail": [
          {
            "src": "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTWUd7Rc2DDC4TjrnRlrMRm1nedf39SMTqbH34XOVdV1Uz4aQ4X_FVCGxQ",
            "width": "209",
            "height": "241"
          }
        ],
        "metatags": [
          {
            "og:type": "website",
            "viewport": "width=device-width, initial-scale=1.0",
            "og:title": "Jennifer : undefined",
            "og:description": "CV, location, contact info for Jennifer : undefined"
          }
        ],
        "cse_image": [
          {
            "src": "https://sths.devapps-dlnkeicmwjs0192.com/contact/assets/STHS-WeCare-careers-smiling-doctor.png"
          }
        ]
      }
    },
  ]
}


*/

